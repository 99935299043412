import React from "react";
import "./MarketItem.scss"
import {NavLink} from "react-router-dom";

function MarketItem(props) {
    return (
        <NavLink to={`/${props?.item?.urlPage}`} className="market-item">
            {props.item.img !== '' &&
            <div className="img-wrapper">
                <img src={props?.item?.image} alt=""/>
            </div>
            }

            <div className="info-block">
                {props.item.title !== '' &&
                <div className="title" dangerouslySetInnerHTML={{__html: props.item.name}} />
                }
                {props.item.additionalTitle !== '' &&
                <div className="description"
                     dangerouslySetInnerHTML={{__html:props.item.additionalTitle}} />
                }
            </div>
        </NavLink>
    )
}

export default MarketItem
import React, {useEffect} from "react";
import "./Home.scss"
import ProductsTab from "../../component/ProductsTab/ProductsTab";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {fetchCreatePage} from "../../store/actions/createPage"
import Loader from "../../component/Loader/Loader";
import component from "../../component/component";


function Home(props) {
    const pageSettings = useSelector(state=>state.pageSettings)
    const data = useSelector(state => state.createPage, shallowEqual)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCreatePage('home',pageSettings.langCode))
    }, [])
    useEffect(() => {
        dispatch(fetchCreatePage('home',pageSettings.langCode));
    }, [pageSettings.langCode])
    return (
        <div className="page-container home-page">

            {
                data.loading
                    ?
                    <Loader/>
                    :
                    <>

                        {
                            data?.pageData?.content &&
                            data.pageData.content.map((item, index) => {
                                let ComponentBlock = component(item.typeBlock)
                                return (
                                    <ComponentBlock data={item} key={`pageBlock_${index}`}/>
                                )
                            })
                        }
                    </>
            }


            <ProductsTab/>
        </div>
    )
}

export default Home
import {MENU_OPEN, MENU_CLOSE, PAGE_LANGUAGE} from "./actionTypes"

export function menuOpen () {
    return {
        type: MENU_OPEN
    }
}

export function menuClose () {
    return {
        type: MENU_CLOSE
    }
}

export function changeLanguage (lng) {
    return {
        type: PAGE_LANGUAGE,
        lng
    }
}
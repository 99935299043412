import {PAGE_START, PAGE_SUCCESS, PAGE_ERROR} from "./actionTypes";
import axios from "../../axios/axios-store";

export function fetchCreatePage(pageURL, langCode) {
    return async dispatch => {
        dispatch(fetchPageStart)

        try {
            const response = await axios.post(
                `pages/${pageURL}`,
                {
                    language: langCode
                }
            )
            dispatch(fetchPageSuccess(response?.data))
        } catch (e) {
            dispatch(fetchPageError(e))
        }
    }
}



export function fetchPageStart() {
    return {
        type: PAGE_START
    }
}

export function fetchPageSuccess(data) {
    return {
        type: PAGE_SUCCESS,
        data
    }
}

export function fetchPageError(e) {
    return {
        type: PAGE_ERROR,
        error: e
    }
}
import React, {useEffect} from "react";
import "./Language.scss"
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {fetchLang} from "../../store/actions/language";
import {changeDefaultLanguage,translateText} from "../../store/actions/pageSettings";

function Language() {
    const lang = useSelector(state => state.language, shallowEqual)
    const pageSetting = useSelector(state => state.pageSettings, shallowEqual)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchLang())
    }, [])

    function handleLanguageClick(id, code) {
        dispatch(changeDefaultLanguage(id, code))
        dispatch(translateText(code))
    }

    return (
        <div className="language-list">
            {
                lang?.languageList?.ru &&
                Object.keys(lang.languageList).map((item, index) => {
                    return (
                        <div className={`item ${pageSetting.langId === lang.languageList[item].id && "active"}`}
                             key={"lang_" + lang.languageList[item].code}
                             onClick={() => handleLanguageClick(lang.languageList[item].id, lang.languageList[item].code)}>
                            {lang.languageList[item].code}
                        </div>
                    )
                })

            }
        </div>
    )

}

export default Language
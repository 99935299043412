import {LANG_SUCCESS,LANG_ERROR} from "../actions/actionTypes";

const languageState = {
    error: null,
    languageList: [],

}

export default function languageReducer(state=languageState,action){
    switch (action.type) {
        case LANG_SUCCESS :
            return{
                ...state,
                languageList:action.data
            }
        case LANG_ERROR :
            return  {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}
import {React, useState} from "react";
import "./VideoScreen.scss"

function VideoScreen(props) {
    const [muteTrigger, setMute] = useState(false)
    return (
        <div className="video-screen">
            <div role="button"
                 className={`mute-icon ${muteTrigger === true ? 'active' : null}`}
                 onClick={() => setMute(!muteTrigger)}
            >
                <i className="icon icon-volume-high _on"></i>
                <i className="icon icon-volume-off _off"></i>
            </div>
            <video loop
                   autoPlay
                   muted={muteTrigger ? false : true}
                   playsInline
                   style={{"backgroundImage": `url(${props?.data?.image ? props.data.image : ''})`}}
                   preload="none"

            >
                {
                    props?.data?.video_mp4 &&
                    <source src={props.data.video_mp4}
                            type="video/mp4"/>
                }
                {
                    props?.data?.video_ogg &&
                    <source src={props.data.video_ogg}
                            type="video/ogg; codecs=&quot;theora, vorbis&quot;"/>
                }
                {
                    props?.data?.video_ogg &&
                    <source src={props.data.video_ogg}
                            type="video/webm"/>
                }




            </video>
        </div>
    )
}

export default VideoScreen
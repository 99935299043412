import {PAGE_START,PAGE_SUCCESS,PAGE_ERROR} from "../actions/actionTypes";

const createPageState = {
    loading: false,
    pageData: null,
    error: null
}

export default function createPageReducer(state=createPageState, action){
    switch (action.type) {
        case PAGE_START :
            return {
                ...state, loading: true
            }

        case PAGE_SUCCESS :
            return {
                ...state,
                loading: false,
                pageData: action.data
            }
        case PAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
export  const MENU_OPEN = 'MENU_OPEN'
export  const MENU_CLOSE = 'MENU_CLOSE'
export  const PAGE_LANGUAGE = 'PAGE_LANGUAGE'

export const MAIN_MENU_START = 'MAIN_MENU_START'
export const MAIN_MENU_SUCCESS = 'MAIN_MENU_SUCCESS'
export const MAIN_MENU_ERROR= 'MAIN_MENU_ERROR'

export const PAGE_SET_START = 'PAGE_SET_START'
export const PAGE_SET_SUCCESS = 'PAGE_SET_SUCCESS'
export const PAGE_SET_ERROR= 'PAGE_SET_ERROR'
export const PAGE_SET_LANG= 'PAGE_SET_LANG'

export const LANG_START = 'LANG_START'
export const LANG_SUCCESS = 'LANG_SUCCESS'
export const LANG_ERROR= 'LANG_ERROR'

export const LAYOT_START = 'LAYOT_START'
export const LAYOT_SUCCESS = 'LAYOT_SUCCESS'
export const LAYOT_ERROR= 'LAYOT_ERROR'

export const PAGE_START = 'PAGE_START'
export const PAGE_SUCCESS = 'PAGE_SUCCESS'
export const PAGE_ERROR= 'PAGE_ERROR'

export const TRANSLATE_TEXT_SUCCESS = 'TRANSLATE_TEXT_SUCCESS'

export const CATEGORIES_START = 'CATEGORIES_START'
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS'
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR'

export const CATEGORIES_SECOND_START = 'CATEGORIES_SECOND_START'
export const CATEGORIES_SECOND_SUCCESS = 'CATEGORIES_SECOND_SUCCESS'
export const CATEGORIES_SECOND_ERROR = 'CATEGORIES_SECOND_ERROR'
export const CATEGORIES_SECOND_RESET = 'CATEGORIES_SECOND_RESET'

export const CATEGORIES_ACTIVE_START = 'CATEGORIES_ACTIVE_START'
export const CATEGORIES_ACTIVE_SUCCESS = 'CATEGORIES_ACTIVE_SUCCESS'
export const CATEGORIES_ACTIVE_ERROR = 'CATEGORIES_ACTIVE_ERROR'

export const MARKETS_START = 'MARKETS_START'
export const MARKETS_SUCCESS = 'MARKETS_SUCCESS'
export const MARKETS_ERROR = 'MARKETS_ERROR'

export const MARKET_START = 'MARKET_START'
export const MARKET_SUCCESS = 'MARKET_SUCCESS'
export const MARKET_ERROR = 'MARKET_ERROR'

export const CATEGORY_ACTIVE_TEXT = 'CATEGORY_ACTIVE_TEXT'
export const CATEGORY_ACTIVE_LINK = 'CATEGORY_ACTIVE_LINK'
export const CATEGORY_ACTIVE_CONTENT = 'CATEGORY_ACTIVE_CONTENT'
export const CATEGORIES_TREE = "CATEGORIES_TREE"

export const SCHEMA_SUCCESS = 'SCHEMA_SUCCESS'
export const SCHEMA_ROOT_ID = 'SCHEMA_ROOT_ID'
export const SCHEMA_SECOND_ID = 'SCHEMA_SECOND_ID'
export const SCHEMA_MARKET_ID = 'SCHEMA_MARKET_ID'
export const SCHEMA_SINGLE_MARKET_ID = 'SCHEMA_SINGLE_MARKET_ID'
export const SCHEMA_MARKET_SEARCH = 'SCHEMA_MARKET_SEARCH'

export const SOCIAL_SUCCESS = 'SOCIAL_SUCCESS'
export const SOCIAL_ERROR = 'SOCIAL_ERROR'

export const BACK_ACTIVE_CATEGORY = 'BACK_ACTIVE_CATEGORY'
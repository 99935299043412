import React from "react";
import VideoScreen from "./VideoScreen/VideoScreen";
import TextBlock from "./TextBlock/TextBlock";
import LinkBlock from "./LinkBlock/LinkBlock";



const Components = {
	video: VideoScreen,
	twoColumnBlock: TextBlock,
	twoColumnWithImagesAndLinksBlock: LinkBlock,
	addressBlock:TextBlock,
	phonesBlock: TextBlock,
	mapBlock:TextBlock
};

export default block => {
	if (typeof Components[block] !== "undefined") {
		return  Components[block]
	}
	return React.createElement(
		() => <div>The component {block.component} has not been created yet.</div>,
		{ key: block._uid }
	);
};
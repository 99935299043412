import React, {useEffect} from "react"
import "./Category.scss"
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {
    backActiveCategory,
    fetchCreateCategoriesActive
} from "../../store/actions/createCategory";
import TextBlock from "../../component/TextBlock/TextBlock";
import MainGallery from "../../component/MainGallery/MainGallery";
import Loader from "../../component/Loader/Loader";
import TabsText from "../../component/TabsText/TabsText";
import MarketItem from "../../component/MarketItem/MarketItem";


const Category = props => {
    const pageSettings = useSelector(state => state.pageSettings)
    const data = useSelector(state => state.createCategory.activeCategory, shallowEqual)
    const categoryAllSettings = useSelector(state => state.createCategory, shallowEqual)
    const dispatch = useDispatch()
    const content = {
        blockTitle: '',
        blockText: data.description
    }
    useEffect(() => {
        dispatch(fetchCreateCategoriesActive(props.urlPage, pageSettings.langCode, categoryAllSettings.backActiveCategory))
    }, [])


    useEffect(() => {
        dispatch(fetchCreateCategoriesActive(props.urlPage, pageSettings.langCode, categoryAllSettings.backActiveCategory))
    }, [pageSettings.langCode])
    return (
        <div className="page-container">
            {
                categoryAllSettings.loadingActive
                    ?
                    <Loader/>
                    :
                    <>
                        <h1 className="page-title blocks-padding" dangerouslySetInnerHTML={{__html: data.h1}}/>
                        {data.images &&
                        <MainGallery gallery={data.images}/>
                        }
                        <TextBlock data={content} titleClass="_small"/>

                        {categoryAllSettings.rootCategoriesSecond && categoryAllSettings.rootCategoriesSecond.length > 0 &&
                        <div className="section-title _center _block blocks-padding">
                            {pageSettings?.translate?.find_title}
                        </div>
                        }
                        {categoryAllSettings.rootCategoriesSecond && categoryAllSettings.rootCategoriesSecond.length > 0 &&
                        <>

                            <TabsText data={categoryAllSettings.rootCategoriesSecond}
                                      marketParent={categoryAllSettings.backActiveCategory ? categoryAllSettings.backActiveCategory : categoryAllSettings.marketsParent}
                                      categoryAllSettings={categoryAllSettings}
                            />
                        </>
                        }
                        {
                            categoryAllSettings.loadingMarkets
                                ?
                                <Loader/>
                                :
                                <div className="catalog-list">
                                    {
                                        categoryAllSettings.markets &&
                                        categoryAllSettings.markets.map((marketItem, index) => {
                                            return (
                                                <MarketItem key={"innerCategory" + index} item={marketItem}/>
                                            )
                                        })
                                    }

                                    {
                                        categoryAllSettings.markets &&
                                        categoryAllSettings.markets.length % 2 !== 0 &&
                                        <div className={'market-item _empty'}></div>
                                    }
                                </div>
                        }
                    </>
            }
        </div>
    )
}

export default Category
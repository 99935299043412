import React from "react";
import Home from "./Pages/Home/Home";
import Category from "./Pages/Category/Category";
import Contact from "./Pages/Contact/Contact";
import MarketPage from "./Pages/MarketPage/MarketPage";
import SchemaPage from "./Pages/SchemaPage/SchemaPage";



const Components = {
	about_us_tpl: MarketPage,
	page_tpl : MarketPage,
	market_place : MarketPage,
	market_type : Category,
	contacts_tpl: Contact,
	main_page_tpl: Home
};

export default block => {
	if (typeof Components[block] !== "undefined") {
		return  Components[block]
	}
	return React.createElement(
		() => <div>The component {block.component} has not been created yet.</div>,
		{ key: block._uid }
	);
};
import {MENU_OPEN, MENU_CLOSE, PAGE_LANGUAGE} from "../actions/actionTypes"

const pageState = {
    menuState: false,
    language: 'ru'
}

export default function pageStateReducer(state = pageState, action) {
    switch (action.type) {
        case MENU_OPEN :
            return {
                ...state,
                menuState: true
            }
        case MENU_CLOSE :
            return {
                ...state,
                menuState: false
            }
        case PAGE_LANGUAGE :
            return {
                ...state,
                language: action.lng
            }
        default :
            return pageState
    }
}
import axios from "../../axios/axios-store";
import {LAYOT_ERROR, LAYOT_START, LAYOT_SUCCESS} from "./actionTypes";

export function fetchLayout() {
    return async dispatch => {
        dispatch(fetchLayoutStart())

        try {
            const response = await axios.post('layouts')
            dispatch(fetchLayoutSuccess(response?.data?.layouts))
        } catch (e) {
            dispatch(fetchLayoutError(e))
        }
    }
}

export function fetchLayoutStart() {
    return {
        type: LAYOT_START
    }
}

export function fetchLayoutSuccess(data) {
    return {
        type: LAYOT_SUCCESS,
        data
    }
}

export function fetchLayoutError(e) {
    return {
        type: LAYOT_ERROR,
        error: e
    }
}
import React from "react"
import "./SiteWork.scss"
import {shallowEqual, useSelector} from "react-redux";
import Loader from "../../Loader/Loader";

function SiteWork() {
    const pageSettings = useSelector(state => state?.pageSettings, shallowEqual)

    return (

        <>
            {
                pageSettings.loading === true
                    ?
                    <Loader/>
                    :
                    <div className="site-workTime">

                        <div className="title">
                            {pageSettings?.translate?.aside_time}
                        </div>

                        {pageSettings.workTime !== "" &&
                        <div className="text" dangerouslySetInnerHTML={{__html: pageSettings.workTime[pageSettings.langId]}}/>
                        }

                    </div>
            }
        </>

    )
}

export default SiteWork
import React, {useEffect, useState} from "react";
import "./TabsText.scss"
import TextBlock from "../TextBlock/TextBlock";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchMarkets,
    categoryActiveName,
    categoryActiveContent,
    schemaSecondCategoryId
} from "../../store/actions/createCategory";
import Slider from "react-slick";
import "../../pluginStyle/slick.scss";


function TabsText(props) {
    const tabs = props.data
    const pageSettings = useSelector(state => state.pageSettings)
    const activeCategory = useSelector(state => state.createCategory.schemaSecondCategoryId)
    const [marketParent, setMarketParent] = useState(props.marketParent)
    const dispatch = useDispatch()
    const categoryAllSettings = props.categoryAllSettings

    const content = {
        blockTitle: categoryAllSettings.activeCategoryName,
        blockText: categoryAllSettings.activeCategoryContent
    }
    const handleTaClick = (catId, urlPage, name, content) => {
        setMarketParent(catId)
        dispatch(fetchMarkets(catId, pageSettings.langCode))
        dispatch(categoryActiveName(name))
        dispatch(categoryActiveContent(content))
    }

    const tabSetting = {
        dots: false,
        arrows: false,
        infinite: true,
        variableWidth: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 750,
        slidesToScroll: 1,
    }
    const activeClass = (id,index) => {
        if(!marketParent){
            return index === 0 && 'active'
        }else{
            return marketParent === id && 'active'
        }
    }
    return (

        <div className='tabs-text'>
            {
                tabs &&
                <>
                    <div className={`tab-list ${tabs.length === 2 ? '_half' : ''}`}>
                        {
                            tabs.map((item, index) => {
                                return (
                                    <button key={"tab" + index}
                                            className={`tab ${activeClass(item.categoryID,index)}`}
                                            onClick={() => handleTaClick(item.categoryID, item.urlPage, item.name, item.description)}
                                            dangerouslySetInnerHTML={{__html: item.name}}
                                    >
                                    </button>
                                )
                            })
                        }
                    </div>
                    <div className={`tab-list _mobile ${tabs.length === 2 ? '_half' : ''}`}>
                        <Slider {...tabSetting}>
                            {
                                tabs.map((item, index) => {
                                    return (
                                        <button key={"tab" + index}
                                                className={`tab ${activeClass(item.categoryID,index)}`}
                                                onClick={() => handleTaClick(item.categoryID, item.urlPage, item.name, item.description)}
                                                dangerouslySetInnerHTML={{__html: item.name}}
                                        >
                                        </button>
                                    )
                                })
                            }
                        </Slider>

                    </div>
                    <div className="panel-text-list">
                        <TextBlock data={content} titleClass="_small _bold"/>
                    </div>
                </>
            }
        </div>

    )
}

export default TabsText
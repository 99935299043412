import {
    CATEGORIES_START, CATEGORIES_SUCCESS, CATEGORIES_ERROR,
    CATEGORIES_SECOND_START, CATEGORIES_SECOND_SUCCESS, CATEGORIES_SECOND_ERROR, CATEGORIES_SECOND_RESET,
    CATEGORIES_ACTIVE_START, CATEGORIES_ACTIVE_SUCCESS, CATEGORIES_ACTIVE_ERROR,
    MARKETS_START, MARKETS_SUCCESS, MARKETS_ERROR,
    MARKET_START, MARKET_SUCCESS, MARKET_ERROR,
    CATEGORY_ACTIVE_TEXT, CATEGORY_ACTIVE_LINK, CATEGORY_ACTIVE_CONTENT, CATEGORIES_TREE,
    SCHEMA_SUCCESS, SCHEMA_ROOT_ID, SCHEMA_SECOND_ID, SCHEMA_MARKET_ID,SCHEMA_MARKET_SEARCH,SCHEMA_SINGLE_MARKET_ID,
    BACK_ACTIVE_CATEGORY
} from "../actions/actionTypes";

const createCategory = {
    loading: false,
    rootCategories: [],
    loadingActive: null,
    activeCategory: [],
    error: null,
    firstCategoryID: '',
    activeCategoryLink: '',
    activeCategoryName: '',
    activeCategoryContent: '',
    loadingSecond: false,
    rootCategoriesSecond: [],
    markets: [],
    loadingMarkets: null,
    marketsParent: null,
    market: [],
    loadingMarket: null,
    errorSecond: null,
    tabItem: [],
    schemaIcons: [],
    schemaRenters: [],
    schemaRootCategoryId: null,
    schemaSecondCategoryId: null,
    schemaMarketId: [],
    schemaSingleMarketId: null,
    searchMarketText: '',
    categoriesTree: [],
    backActiveCategory: null
}

export default function createCategoryReducer(state = createCategory, action) {
    switch (action.type) {
        case CATEGORIES_START :
            return {
                ...state, loading: true
            }

        case CATEGORIES_SUCCESS :
            return {
                ...state,
                loading: false,
                rootCategories: action.data,
                firstCategoryID: action.data[0].categoryID
            }
        case CATEGORIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case CATEGORIES_SECOND_START :
            return {
                ...state, loadingSecond: true, firstCategoryID: action.firstID
            }

        case CATEGORIES_SECOND_SUCCESS :
            return {
                ...state,
                loadingSecond: false,
                rootCategoriesSecond: action.data,
                // marketsParent: action.data[0].categoryID
            }
        case CATEGORIES_SECOND_RESET :
            return {
                ...state,
                rootCategoriesSecond: [],
                loadingSecond: false
            }
        case CATEGORIES_SECOND_ERROR:
            return {
                ...state,
                loadingSecond: false,
                errorSecond: action.error
            }


        case MARKETS_START :
            return {
                ...state, loadingMarkets: true
            }

        case MARKETS_SUCCESS :
            return {
                ...state,
                loadingMarkets: false,
                markets: action.data,
                // marketsParent: action.parentID,
            }
        case MARKETS_ERROR:
            return {
                ...state,
                loadingMarkets: false,
                errorSecond: action.error
            }
        case MARKET_START :
            return {
                ...state, loadingMarket: true
            }

        case MARKET_SUCCESS :
            return {
                ...state,
                loadingMarket: false,
                market: action.data,
            }
        case MARKET_ERROR:
            return {
                ...state,
                loadingMarket: false,
                errorSecond: action.error
            }

        case CATEGORIES_ACTIVE_START :
            return {
                ...state, loadingActive: true
            }

        case CATEGORIES_ACTIVE_SUCCESS :
            return {
                ...state,
                loadingActive: false,
                activeCategory: action.data
            }
        case CATEGORIES_ACTIVE_ERROR:
            return {
                ...state,
                loadingActive: false,
                errorSecond: action.error
            }
        case CATEGORY_ACTIVE_TEXT :
            return {
                ...state,
                activeCategoryName: action.categoryName,
            }
        case CATEGORY_ACTIVE_LINK :
            return {
                ...state,
                activeCategoryLink: action.categoryLink,
            }
        case CATEGORY_ACTIVE_CONTENT :
            return {
                ...state,
                activeCategoryContent: action.content,
            }
        case CATEGORIES_TREE :
            return {
                ...state,
                categoriesTree: action.data
            }
        case SCHEMA_SUCCESS :
            return {
                ...state,
                schemaIcons: action.data.codes,
                schemaRenters: action.data.renters

            }
        case SCHEMA_ROOT_ID :
            return {
                ...state,
                schemaRootCategoryId: action.id
            }
        case SCHEMA_SECOND_ID :
            return {
                ...state,
                schemaSecondCategoryId: action.id
            }

        case SCHEMA_MARKET_ID :
            return {
                ...state,
                schemaMarketId: action.id
            }
        case SCHEMA_SINGLE_MARKET_ID :
            return {
                ...state,
                schemaSingleMarketId: action.id
            }
        case SCHEMA_MARKET_SEARCH :
            return {
                ...state,
                searchMarketText: action.text,
                schemaMarketId: []
            }
        case BACK_ACTIVE_CATEGORY :
            return  {
                ...state,
                backActiveCategory: action.id
            }
        default:
            return state
    }
}
import {SOCIAL_SUCCESS, SOCIAL_ERROR} from "../actions/actionTypes";

const socialState = {
    social: [],
    error: null
}

export default function createSocialReducer(state = socialState, action) {
    switch (action.type) {
        case SOCIAL_SUCCESS :
            return {
                ...socialState,
                social: action.data
            }
        case SOCIAL_ERROR :
            return {
                ...socialState,
                error: null
            }
        default :
            return state
    }
}
import React, {useEffect} from "react";
import {NavLink} from 'react-router-dom'
import "./MainMenu.scss"
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {menuClose} from "../../../store/actions/pageState";
import {fetchMenu} from "../../../store/actions/createMenu";

import Loader from "../../Loader/Loader";
import {backActiveCategory} from "../../../store/actions/createCategory";


function MainMenu() {
    const pageSetting = useSelector(state=>state.pageSettings)
    const state = useSelector(state => state.createMenu, shallowEqual)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchMenu(pageSetting.langCode))
    }, [])
    useEffect(() => {
        dispatch(fetchMenu(pageSetting.langCode))
    }, [pageSetting.langCode])

    return (
        <>
            {
                state.loading === true
                    ?
                    <Loader/>
                    :
                    <nav className={"main-menu"}>
                        {state.menuList.map(route => (
                            <NavLink
                                key={route.url}
                                to={`/${route.url}`}
                                className="menu-item"
                                exact
                                activeClassName="active"
                                onClick={() => {
                                    dispatch(backActiveCategory(null))
                                    dispatch(menuClose())
                                }}
                            >
                       <span>
                             {route.title}
                       </span>
                            </NavLink>
                        ))}
                    </nav>
            }

        </>
    )
}

export default MainMenu
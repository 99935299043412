import React from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {
    fetchMarkets,
    schemaMarketId,
    schemaSecondCategoryId,
    schemaRootCategoryId
} from "../../store/actions/createCategory";
import {NavLink, useLocation, useHistory} from "react-router-dom";


const SchemaItem = props => {
    const pageSettings = useSelector(state => state.pageSettings, shallowEqual)
    const category = props.data.rootCategoriesSecond
    const title = props.data.activeCategoryName
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const handleItemClick = (id) => {
        dispatch(fetchMarkets(id, pageSettings.langCode))
        dispatch(schemaSecondCategoryId(id))
    }
    const handleItemLastClick = (id, url) => {
        // if (!props.schema) {
        //     dispatch(schemaMarketId(id))
        // }
        if (window.outerWidth < 768) {
            window.scrollTo({
                top: props.image.offsetTop - 200,
                behavior: "smooth"
            });
        }

        if (props.schema) {
            location.pathname = `/${url}`
            history.push(`/${url}`)
        }
    }

    const handleReturnCLick = () => {
        props.pageHandler(props.pageStateReset)
        props.pageStateReset === 1 ? props.categoryHandler(false) : props.categoryChildHandler(false)
        if (props.resetMarketId) {
            dispatch(schemaMarketId([]))
        }
        if (props.resetCategories) {
            dispatch(schemaSecondCategoryId(null))
            dispatch(schemaRootCategoryId(null))
        }
        if (props.data.rootCategoriesSecond.length === 0) {
            dispatch(schemaRootCategoryId(null))
        }
        if (props.data.schemaSecondCategoryId) {
            dispatch(schemaSecondCategoryId(null))
        }
    }

    let lastItems = []

    if (props.schema) {
        lastItems = props.data.schemaRenters
    } else {
        lastItems = props.data.markets
    }

    const hideItem = (id, name) => {
        if (props.schema) {
            if (props.data.searchMarketText.length > 2) {
                let regular = new RegExp('' + props.data.searchMarketText + '', 'gi')
                return name.match(regular)
            } else {
                if (typeof props.data.schemaMarketId === "object") {
                    return props.data.schemaMarketId.includes(id)
                } else {
                    return props.data.schemaMarketId === id
                }

            }
        } else {
            return true
        }
    }

    return (
        <>
            <div className="list-return" onClick={() => {
                handleReturnCLick()
            }}>
                <i className="icon icon-left-arrow"></i>{pageSettings?.translate?.return_category_title}
            </div>
            {!props.schema &&
            <div className="list-title">
                {title}
            </div>
            }
            {props.last
                ?
                lastItems.map((item, categoryIndex) => {
                    return (
                        item?.codeIDs?.length > 0 &&
                        <div
                            className={`list-item _last ${item.productID} ${hideItem(item.productID, item.name) ? '' : '_hide'}`}
                            onClick={() => {
                                handleItemLastClick(item.productID, item.urlPage)
                            }}
                            key={categoryIndex}>
                            <span dangerouslySetInnerHTML={{__html: item.name}}></span>
                            <NavLink to={`/${item.urlPage}`}></NavLink>
                        </div>
                    )
                })
                :
                <>
                    {
                        category.length > 0 &&
                        category.map((item, categoryIndex) => {
                            return (

                                <div className={`list-item ${item.categoryID}`}
                                     onClick={() => {
                                         props.pageHandler(3)
                                         handleItemClick(item.categoryID)
                                     }
                                     }
                                     key={categoryIndex}
                                     dangerouslySetInnerHTML={{__html: item.name}}
                                />
                            )
                        })
                    }
                </>
            }

        </>

    )
}

export default SchemaItem
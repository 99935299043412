import {SOCIAL_SUCCESS, SOCIAL_ERROR} from "./actionTypes";
import axios from "../../axios/axios-store";


export function fetchSocial() {
    return async dispatch => {
        try {
            const response = await axios.post('socials')
            dispatch(fetchSocialSuccess(response?.data?.socialLinks))
        } catch (e) {
            dispatch(fetchSocialError(e))
        }
    }
}

export function fetchSocialSuccess(data) {
    return {
        type: SOCIAL_SUCCESS,
        data
    }
}

export function fetchSocialError(e) {
    return {
        type: SOCIAL_ERROR,
        error: e
    }
}
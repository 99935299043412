import React, {useEffect} from "react";
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchSettings} from "./store/actions/pageSettings"
import {fetchLayout} from "./store/actions/createRoute"
import ScrollToTop from "./component/ScrollToTop/ScrollToTop"
import TopScroll from "./component/TopScroll/TopScroll";
import componentRoute from "./componentRoute";

import Sidebar from "./component/Sidebar/Sidebar";
import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import SchemaPage from "./Pages/SchemaPage/SchemaPage";


import './App.scss';
import './font/fontello/css/fontello.css'


function App() {
    const routes = useSelector(state => state.createRoute, shallowEqual)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSettings())
        dispatch(fetchLayout())
    }, [])
    return (
        <Router>
            <ScrollToTop/>
            <div className="page-wrapper">
                <Sidebar/>
                <div className="page-inner">
                    <Header/>
                    <div className={"page-content"}>
                        {routes.routes.length > 0 &&
                        <>
                            {routes.routes.map(({urlPage, layoutPath}) => {
                                let Component = componentRoute(layoutPath)
                                return (
                                    <Route key={urlPage} exact path={'/' + urlPage}>

                                        {({match}) => (
                                            <CSSTransition
                                                in={match != null}
                                                timeout={600}
                                                classNames="page"
                                                unmountOnExit
                                            >
                                                <div className="page">
                                                    <Component urlPage={urlPage}/>
                                                </div>
                                            </CSSTransition>
                                        )}
                                    </Route>
                                )
                            })}
                            <Route exact path={'/schema'}>

                                {({match}) => (
                                    <CSSTransition
                                        in={match != null}
                                        timeout={600}
                                        classNames="page"
                                        unmountOnExit
                                    >
                                        <div className="page">
                                            <SchemaPage urlPage={'schema'}/>
                                        </div>
                                    </CSSTransition>
                                )}
                            </Route>
                        </>
                        }
                    </div>
                    <Footer/>
                </div>
            </div>
            <TopScroll />
        </Router>

    );
}

export default App;

import React, {useState, useEffect, useRef} from "react";
import "./SchemaPage.scss"
import {ReactComponent as SchemaImage} from "../../images/schema/schema_clear_with_icon.svg"

import SchemaItem from "../../component/Schema/SchemaItem";

import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import {useSelector, useDispatch} from "react-redux";
import {
    fetchCreateCategories,
    fetchCreateCategoriesSecond,
    categoryActiveName,
    fetchSchemaIcons,
    schemaRootCategoryId,
    schemaSecondCategoryId,
    schemaMarketId,
    schemaSingleMarketId,
    schemaMarketSearch,
    fetchCategoriesTree,
    fetchCreateCategoriesSecondSuccess
} from "../../store/actions/createCategory"
import {NavLink} from "react-router-dom";

function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}


const SchemaPage = props => {
    const [activeCategory, setActiveCategory] = useState(false)
    const [activeChildCategory, setChildActiveCategory] = useState(false)
    const inputSearch = useRef()
    const [pageState, setPageState] = useState(1)
    const image = useRef()
    const imageWrapper = useRef()
    const listWrapper = useRef()
    const data = useSelector(state => state.createCategory)
    const pageSetting = useSelector(state => state.pageSettings)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchCreateCategoriesSecondSuccess([]))
        dispatch(fetchCreateCategories(pageSetting.langCode))
        dispatch(fetchSchemaIcons(pageSetting.langCode))
        dispatch(fetchCategoriesTree(pageSetting.langCode))
        dispatch(schemaSecondCategoryId(null))
        setPageState(0)
        return ()=>{
            dispatch(schemaMarketId([]))
            dispatch(schemaSingleMarketId(null))
            dispatch(schemaRootCategoryId(null))
        }
    }, [])
    useEffect(() => {
        if (data.schemaSingleMarketId) {
            if (data.rootCategoriesSecond.length > 0) {
                setPageState(4)
            } else {
                setPageState(3)
            }
        }
    }, [data.categoriesTree])

    useEffect(() => {
        dispatch(fetchCreateCategories(pageSetting.langCode))
        dispatch(fetchSchemaIcons(pageSetting.langCode))
        dispatch(fetchCategoriesTree(pageSetting.langCode))
    }, [pageSetting.langCode])


    const handleCategoriesActive = (id, name) => {
        dispatch(schemaSingleMarketId(null))
        dispatch(fetchCreateCategoriesSecond(id, pageSetting.langCode))
        dispatch(schemaRootCategoryId(id))
        dispatch(categoryActiveName(name))
    }
    const handleSchemaIcon = (id) => {
        dispatch(schemaSingleMarketId(null))
        if (data.rootCategoriesSecond.length > 0) {
            setPageState(4)
        } else {
            setPageState(3)
        }
        if (window.outerWidth < 768) {
            window.scrollTo({
                top: listWrapper.current.offsetTop - 200,
                behavior: "smooth"
            });
        }
        dispatch(schemaMarketId(id))
    }

    const handleSearchKeyUp = (e) => {
        dispatch(schemaSingleMarketId(null))
        if (inputSearch.current.value.length > 2) {
            dispatch(schemaMarketSearch(inputSearch.current.value))
            if (data.rootCategoriesSecond.length > 0) {
                setPageState(5)
            } else {
                setPageState(4)
            }
        } else {
            setPageState(1)
        }
    }

    const iconClass = (id, renterId) => {
        if (data.schemaSingleMarketId) {
            return renterId.includes(data.schemaSingleMarketId) ? ' active' : ''
        }
        if (data.schemaMarketId.length === 0) {
            if (data.schemaSecondCategoryId) {
                return id.includes(data.schemaSecondCategoryId) ? ' active' : ''
            }
        } else {
            if (!data.schemaSingleMarketId) {
                return arrayEquals(id, data.schemaMarketId) ? ' active' : ''
            }
        }
        if (data.rootCategoriesSecond.length === 0) {
            return id.includes(data.schemaRootCategoryId) ? ' active' : ''
        }

    }

    const showRenters = (renterList) => {
        let renter = renterList.map((item) => {
            if (item?.codeIDs?.length > 0) {
                return (
                    <div key={item.productID}
                         className={`list-item _last`}
                    >
                        <span dangerouslySetInnerHTML={{__html: item.name}}></span>
                        <NavLink to={`/${item.urlPage}`}></NavLink>
                    </div>
                )
            }

        })

        return (
            renter
        )
    }

    const createTree = (list) => {
        let category = list.map((item) => {
            return (
                <div key={item.categoryID}
                     className={`list-inner ${item.categoryID} ${data.schemaMarketId.includes(item.categoryID) ? "_show" : "_hide"}`}>
                    <div className={"list-title"}>
                        {item.name}
                    </div>
                    {
                        item?.children?.length > 0
                            ?
                            createTree(item.children)
                            :
                            showRenters(item.renters)
                    }

                </div>
            )

        })
        return (
            <div>
                {category}
            </div>
        )
    }

    return (
        <div className="page-container schema-page">
            <h1 className="page-title">{pageSetting?.translate?.market_map_title}</h1>
            <div className="schema-info-wrapper">
                <div className="schema-info blocks-padding">
                    <i className="icon icon-info"></i>
                    <div className="schema-text">
                        {pageSetting?.translate?.scheme_helper}
                    </div>
                </div>
                <div className="schema-title blocks-padding">{pageSetting?.translate?.renter_list_title}</div>
            </div>
            <div className="schema-info-wrapper mobile-schema-info">
                <div className="schema-info blocks-padding">
                    <i className="icon icon-palec"></i>
                    <div className="schema-text">
                        {pageSetting?.translate?.scheme_helper}
                    </div>
                </div>
            </div>
            <div className="schema-block">
                <div className="schema-image-wrapper" ref={imageWrapper}>
                    <TransformWrapper
                        scale={0.65}
                        options={{
                            minScale: 0.65,
                            maxScale: 2.4,
                        }}
                        pan={{
                            velocity: true
                        }}
                        zoomIn={{
                            step: 5.4,
                            animationTime: 500
                        }}
                        zoomOut={{
                            step: 5.4,
                            animationTime: 500
                        }}
                        doubleClick={{
                            disabled: true
                        }}

                    >
                        {({zoomIn, zoomOut, scale, resetTransform, ...rest}) => (
                            <>
                                <TransformComponent>
                                    <SchemaImage ref={image}/>
                                    {
                                        data?.schemaIcons?.length > 0 &&
                                        data.schemaIcons.map((icon, index) => {
                                            return (
                                                <div
                                                    key={`schema_icon_${index}`}
                                                    className={`schema-icon 
                                                    ${iconClass(icon.typeIDs, icon.renterIDs)}`}
                                                    onClick={() => {
                                                        handleSchemaIcon(icon.typeIDs)
                                                    }}
                                                    style={{
                                                        width: icon?.scheme_settings?.width + 'px',
                                                        height: icon?.scheme_settings?.height + 'px',
                                                        left: icon?.scheme_settings?.left + 'px',
                                                        top: icon?.scheme_settings?.top + 'px',
                                                    }}>
                                                    <img src={icon.icon} alt=""/>

                                                </div>
                                            )
                                        })
                                    }
                                </TransformComponent>
                                <div className="zoom-block">
                                    <div className="zoom-control">
                                        <div className="zoom-minus" onClick={zoomOut}>

                                        </div>
                                        <div className="zoom-plus" onClick={zoomIn}>

                                        </div>
                                    </div>
                                    <div className="zoom-info">
                                        <div className="zoom-title">
                                            {pageSetting?.translate?.map_scale_title}
                                        </div>
                                        <div className="zoom-number">
                                            {(scale * 100).toFixed(0)}<span>%</span>
                                        </div>
                                        <div className="zoom-reset" onClick={resetTransform}>
                                            <div className="icon icon-close"></div>
                                            {pageSetting?.translate?.reset_title}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </TransformWrapper>

                </div>
                <div className="schema-info-wrapper mobile-schema-info">
                    <div className="schema-title blocks-padding">{pageSetting?.translate?.renter_list_title}</div>
                </div>
                <div className={`schema-list-wrapper`} ref={listWrapper}>
                    <div className="schema-search blocks-padding">
                        <input type="text"
                               placeholder={pageSetting?.translate?.search_example_title}
                               ref={inputSearch}
                               onKeyUp={() => {
                                   handleSearchKeyUp()
                               }}/>
                        <button><i className="icon icon-loupe"></i><span>{pageSetting?.translate?.search_title} </span>
                        </button>
                    </div>
                    {
                        data.loading === false &&
                        <div className={`schema-list-inner  list-${pageState}`}>
                            <div className="schema-list blocks-padding">
                                {
                                    data.rootCategories.map((item, categoryIndex) => {
                                        return (
                                            item.categoryID !== "19" &&
                                            <div className="list-item"
                                                 onClick={() => {
                                                     handleCategoriesActive(item.categoryID, item.name)
                                                     setPageState(2)
                                                 }}
                                                 key={categoryIndex}>
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {
                                data.rootCategoriesSecond.length > 0 &&
                                <div className="schema-list _inner-category blocks-padding">
                                    <SchemaItem
                                        pageHandler={setPageState}
                                        pageStateReset={pageState - 1}
                                        categoryHandler={setActiveCategory}
                                        categoryChildHandler={setChildActiveCategory}
                                        resetCategories={true}
                                        data={data}/>
                                </div>
                            }

                            {
                                data.markets.length > 0 &&
                                <div className="schema-list 2 _inner-category blocks-padding">
                                    <SchemaItem
                                        pageHandler={setPageState}
                                        pageStateReset={pageState - 1}
                                        categoryHandler={setActiveCategory}
                                        categoryChildHandler={setChildActiveCategory}
                                        resetMarketId={true}
                                        last={true}
                                        image={imageWrapper.current}
                                        data={data}/>
                                </div>
                            }

                            {
                                data.categoriesTree.length > 0 &&
                                <div className="schema-list _inner-category blocks-padding lst">
                                    <div className="list-return"
                                         onClick={() => {
                                             dispatch(schemaMarketId([]))
                                             dispatch(schemaSecondCategoryId(null))
                                             dispatch(schemaRootCategoryId(null))
                                             dispatch(schemaSingleMarketId(null))
                                             setPageState(1)
                                         }}
                                    >
                                        <i className="icon icon-left-arrow"></i>{pageSetting?.translate?.return_category_title}
                                    </div>
                                    {createTree(data.categoriesTree)}
                                </div>
                            }

                            {
                                data.schemaRenters.length > 0 &&
                                <div className="schema-list _inner-category blocks-padding"
                                     style={{
                                         opacity: data.schemaSingleMarketId ? 0 : 1
                                     }}>
                                    <SchemaItem
                                        pageHandler={setPageState}
                                        pageStateReset={1}
                                        categoryHandler={setActiveCategory}
                                        categoryChildHandler={setChildActiveCategory}
                                        resetMarketId={true}
                                        resetCategories={true}
                                        last={true}
                                        schema={true}
                                        image={imageWrapper.current}
                                        data={data}/>
                                </div>
                            }

                        </div>
                    }


                </div>

            </div>
        </div>
    )
}

export default SchemaPage
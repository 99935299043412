import {
    CATEGORIES_START, CATEGORIES_SUCCESS, CATEGORIES_ERROR,
    CATEGORIES_SECOND_START, CATEGORIES_SECOND_SUCCESS, CATEGORIES_SECOND_ERROR, CATEGORIES_SECOND_RESET,
    CATEGORIES_ACTIVE_START, CATEGORIES_ACTIVE_SUCCESS, CATEGORIES_ACTIVE_ERROR,
    MARKETS_START, MARKETS_SUCCESS, MARKETS_ERROR,
    MARKET_START, MARKET_SUCCESS, MARKET_ERROR,
    CATEGORY_ACTIVE_TEXT, CATEGORY_ACTIVE_LINK, CATEGORY_ACTIVE_CONTENT, CATEGORIES_TREE,
    SCHEMA_SUCCESS, SCHEMA_ROOT_ID, SCHEMA_SECOND_ID, SCHEMA_MARKET_ID, SCHEMA_MARKET_SEARCH, SCHEMA_SINGLE_MARKET_ID,
    BACK_ACTIVE_CATEGORY
} from "./actionTypes";
import axios from "../../axios/axios-store";

export function fetchCreateCategories(langCode) {
    return async dispatch => {
        dispatch(fetchCreateCategoriesStart)

        try {
            const response = await axios.post(
                'type/root',
                {
                    language: langCode
                }
            )
            dispatch(fetchCreateCategoriesSuccess(response?.data?.renterTypes))
            dispatch(categoryActiveName(response?.data?.renterTypes[0].name))
            dispatch(categoryActiveLink(response?.data?.renterTypes[0].urlPage))
            dispatch(fetchCreateCategoriesSecond(response?.data?.renterTypes[0].categoryID, langCode))
        } catch (e) {
            dispatch(fetchCreateCategoriesError(e))
        }
    }
}


export function fetchCategoriesTree(langCode) {
    return async dispatch => {
        try {
            const response = await axios.post(
                'renter/tree',
                {
                    language: langCode
                }
            )
            dispatch(CategoriesTree(response.data))
        } catch (e) {
            console.log(e)
        }
    }
}

export function CategoriesTree(data) {
    return {
        type: CATEGORIES_TREE,
        data
    }
}

export function fetchCreateCategoriesStart() {
    return {
        type: CATEGORIES_START
    }
}

export function fetchCreateCategoriesSuccess(data) {
    return {
        type: CATEGORIES_SUCCESS,
        data
    }
}

export function fetchCreateCategoriesError(e) {
    return {
        type: CATEGORIES_ERROR,
        error: e
    }
}

export function fetchCreateCategoriesSecond(parentId, langCode, set, secondIdActive) {
    let catId = secondIdActive ? secondIdActive : parentId
    return async dispatch => {
        dispatch(fetchCreateCategoriesSecondStart(parentId))
        try {
            const response = await axios.post(
                'type/parent/' + parentId,
                {
                    language: langCode
                })

            if (response.data.renterTypes.length > 0) {
                dispatch(fetchCreateCategoriesSecondSuccess(response?.data?.renterTypes))

                let resultActive
                if (secondIdActive) {
                    resultActive = response.data.renterTypes.filter(item => {
                            return item.categoryID === secondIdActive
                        }
                    )
                    resultActive = resultActive[0]
                } else {
                    resultActive = response?.data?.renterTypes[0]
                }

                dispatch(fetchMarkets(resultActive.categoryID, langCode))
                if (set) {
                    dispatch(categoryActiveName(resultActive.name))
                    dispatch(categoryActiveContent(resultActive.description))
                }

            } else {
                dispatch(fetchMarkets(secondIdActive ? secondIdActive : parentId, langCode, true))
                dispatch(resetCreateCategoriesSecond())
            }


        } catch (e) {
            dispatch(fetchCreateCategoriesSecondError(e))
        }
    }
}

export function resetCreateCategoriesSecond() {
    return {
        type: CATEGORIES_SECOND_RESET
    }
}

export function fetchCreateCategoriesSecondSuccess(data) {
    return {
        type: CATEGORIES_SECOND_SUCCESS,
        data
    }
}

export function fetchCreateCategoriesSecondError(e) {
    return {
        type: CATEGORIES_SECOND_ERROR,
        error: e
    }
}

export function fetchCreateCategoriesSecondStart(firstID) {
    return {
        type: CATEGORIES_SECOND_START,
        firstID
    }
}

export function fetchCreateCategoriesActive(pageURL, langCode, secondIdActive) {
    return async dispatch => {
        dispatch(fetchCreateCategoriesActiveStart())
        try {
            const response = await axios.post(
                pageURL,
                {
                    language: langCode
                }
            )

            dispatch(fetchCreateCategoriesSecond(response.data.renterType.categoryID, langCode, true, secondIdActive))
            dispatch(fetchCreateCategoriesActiveSuccess(response.data.renterType))
        } catch (e) {
            dispatch(fetchCreateCategoriesActiveError(e))
        }
    }
}

export function fetchCreateCategoriesActiveSuccess(data) {
    return {
        type: CATEGORIES_ACTIVE_SUCCESS,
        data
    }
}

export function fetchCreateCategoriesActiveError(e) {
    return {
        type: CATEGORIES_ACTIVE_ERROR,
        error: e
    }
}

export function fetchCreateCategoriesActiveStart() {
    return {
        type: CATEGORIES_ACTIVE_START
    }
}

export function categoryActiveName(categoryName) {
    return {
        type: CATEGORY_ACTIVE_TEXT,
        categoryName
    }
}

export function categoryActiveLink(categoryLink) {
    return {
        type: CATEGORY_ACTIVE_LINK,
        categoryLink
    }
}

export function categoryActiveContent(content) {
    return {
        type: CATEGORY_ACTIVE_CONTENT,
        content
    }
}


export function fetchMarkets(parentId, langCode) {
    return async dispatch => {
        dispatch(fetchMarketsStart(parentId))

        try {
            const response = await axios.post(
                'renter/parent/' + parentId,
                {
                    language: langCode
                })
            dispatch(fetchMarketsSuccess(response.data.renters))
        } catch (e) {
            dispatch(fetchMarketsError(e))
        }
    }
}

export function fetchMarketsSuccess(data) {
    return {
        type: MARKETS_SUCCESS,
        data
    }
}

export function fetchMarketsError(e) {
    return {
        type: MARKETS_ERROR,
        error: e
    }
}

export function fetchMarketsStart() {
    return {
        type: MARKETS_START
    }
}

export function fetchMarket(pageURL, langCode) {
    return async dispatch => {
        dispatch(fetchMarketStart())

        try {
            const response = await axios.post(
                pageURL,
                {
                    language: langCode
                })
            dispatch(fetchMarketSuccess(response.data.renter))
        } catch (e) {
            dispatch(fetchMarketError(e))
        }
    }
}

export function fetchMarketSuccess(data) {
    return {
        type: MARKET_SUCCESS,
        data
    }
}

export function fetchMarketError(e) {
    return {
        type: MARKET_ERROR,
        error: e
    }
}

export function fetchMarketStart() {
    return {
        type: MARKET_START
    }
}


export function fetchSchemaIcons(langCode) {
    return async dispatch => {
        try {
            const response = await axios.post(
                'renter/all',
                {
                    language: langCode
                })
            dispatch(fetchSchemaSuccess(response.data))
        } catch (e) {
            console.log('fetchSchemaIcons', e)
        }
    }
}

export function fetchSchemaSuccess(data) {
    return {
        type: SCHEMA_SUCCESS,
        data
    }
}

export function schemaRootCategoryId(id) {
    return {
        type: SCHEMA_ROOT_ID,
        id
    }
}

export function schemaSecondCategoryId(id) {
    return {
        type: SCHEMA_SECOND_ID,
        id
    }
}

export function schemaMarketId(id) {
    return {
        type: SCHEMA_MARKET_ID,
        id
    }
}

export function schemaSingleMarketId(id) {
    return {
        type: SCHEMA_SINGLE_MARKET_ID,
        id
    }
}

export function schemaMarketSearch(text) {
    return {
        type: SCHEMA_MARKET_SEARCH,
        text
    }
}

export function backActiveCategory(id) {
    return {
        type: BACK_ACTIVE_CATEGORY,
        id
    }
}
import axios from "../../axios/axios-store";
import {LANG_ERROR, LANG_START, LANG_SUCCESS} from "./actionTypes";

export function fetchLang() {
    return async dispatch => {
        dispatch(fetchLangStart())

        try {
            const response = await axios.post('languages')
            dispatch(fetchLangSuccess(response.data.languages))
        } catch (e) {
            dispatch(fetchLangError(e))
        }
    }
}

export function fetchLangStart() {
    return {
        type: LANG_START
    }
}

export function fetchLangSuccess(data) {
    return {
        type: LANG_SUCCESS,
        data
    }
}

export function fetchLangError(e) {
    return {
        type: LANG_ERROR,
        error: e
    }
}
import React, { useState, useEffect } from "react";
import "./TopScroll.scss"
import {useSelector} from "react-redux";

const TopScroll = props =>{
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const menuOpen = useSelector(state => state.pageState.menuState)
    const language = useSelector(state=>state.pageSettings.translate)
    const onScroll = (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
        setScrolling(e.target.documentElement.scrollTop > scrollTop);
    }
    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
            setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);
    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    return(
        <div className={`scroll-top-block ${scrollTop > 100 ? '_active' : ''}  ${menuOpen ? '_hide' : ''}`}
             onClick={handleScrollTop}>
            <i className="scroll-icon icon-top-arrow"></i>
            <span className={`scroll-text`}>{language?.btn_up_title}</span>
        </div>
    )
}

export default TopScroll
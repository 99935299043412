import React, {useEffect} from "react"
import "./SiteSocial.scss"
import {fetchSocial} from "../../../store/actions/createSocials"
import {useSelector,useDispatch,shallowEqual} from "react-redux"

function SiteSocial() {
    const socialLinks = useSelector(state=>state.createSocial,shallowEqual)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(fetchSocial())
    },[])
    return (
        <div className="site-social">
            {socialLinks.social.length > 0 &&
                socialLinks.social.map((item, index) => {
                    return (
                        <a href={item.url}
                           className="link"
                           target="_blank"
                           rel="noreferrer"
                           key={"social_" + index}>
                            <i className={`icon ${item.class_css}`}></i>
                        </a>
                    )
                })
            }
        </div>
    )
}

export default SiteSocial
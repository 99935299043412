import React from "react"
import "./GoogleMapComp.scss"
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import mapMarker from "../../images/mapMarker.png"
import {useSelector} from "react-redux";
import Loader from "../Loader/Loader";

const containerStyle = {
    width: '100%',
    height: '600px'
};

const options = {
    zoom: 19,
    disableDefaultUI: true
}

const mapIframe= '<iframe\n' +
    '        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10583.868667634835!2d35.0685112!3d48.4571595!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x54ea1df22d1674b5!2z0J3QsNCz0L7RgNC90YvQuSDRgNGL0L3QvtC6!5e0!3m2!1sru!2sua!4v1618226869901!5m2!1sru!2sua"\n' +
    '        width="100%" height="600" style="border:0;" allowFullScreen="" loading="lazy"></iframe>'

function GoogleMapComp(props) {
    const pageSetting = useSelector(state => state.pageSettings)
    const center = {
        lat: +pageSetting.mapLat,
        lng: +pageSetting.mapLng,
    };
    const position = {
        lat: +pageSetting.mapLat,
        lng: +pageSetting.mapLng,
        icon: mapMarker
    };

    return (
        <div className="contact-map"
             dangerouslySetInnerHTML={{__html:mapIframe}}>

            {/*{*/}
            {/*    pageSetting.loading*/}
            {/*        ?*/}
            {/*        <Loader/>*/}
            {/*        :*/}
            {/*        <LoadScript*/}
            {/*            googleMapsApiKey={pageSetting?.mapKey}*/}
            {/*        >*/}
            {/*            <GoogleMap*/}
            {/*                mapContainerStyle={containerStyle}*/}
            {/*                center={center}*/}
            {/*                options={options}*/}
            {/*            >*/}
            {/*                <Marker*/}
            {/*                    position={position}*/}
            {/*                />*/}
            {/*            </GoogleMap>*/}
            {/*        </LoadScript>*/}
            {/*}*/}

        </div>
    )
}

export default React.memo(GoogleMapComp)
import React from "react";
import "./TextBlock.scss"
import {NavLink} from "react-router-dom";

function TextBlock(props) {
    return (
        <>
            <div className={`text-with-image blocks-padding ${props?.data?.blockTitle ? '' : '_only-content'}`}>
                {props?.data?.blockTitle &&
                <div className="block-img">
                    {props?.data?.blockTitle &&
                    <div className={`section-title ${props.titleClass ? props.titleClass : null}`}
                         dangerouslySetInnerHTML={{__html: props.data.blockTitle}}
                    />
                    }
                    {props?.data?.linkToPage &&
                    <NavLink
                        className="block-link"
                        to={`/${props.data.linkToPage}`}>
                        {props.data.linkText}
                        <i className="icon icon-arrrow-right"></i>
                    </NavLink>
                    }
                    {props?.data?.blockImage &&
                    <div className="img-wrapper">
                        <img src={props?.data?.blockImage} alt=""/>
                    </div>
                    }

                </div>
                }
                {props?.data?.blockText &&
                <div className="text-formatted" dangerouslySetInnerHTML={{__html: props.data.blockText}}></div>
                }
                {props?.data?.blockImage &&
                <div className="img-mobile">
                    <img src={props.data.blockImage} alt=""/>
                </div>
                }
            </div>
        </>

    )
}

export default TextBlock
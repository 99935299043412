import React, {useEffect, useState} from "react";
import "./Contact.scss"
import TextBlock from "../../component/TextBlock/TextBlock";
import GoogleMap from "../../component/GoogleMapComp/GoogleMapComp";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {fetchCreatePage} from "../../store/actions/createPage"
import Loader from "../../component/Loader/Loader";


function Contact(props) {
    const pageSettings = useSelector(state => state.pageSettings)
    const request = useSelector(state => state.createPage, shallowEqual)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCreatePage(props.urlPage, pageSettings.langCode))
    }, [])
    useEffect(() => {
        dispatch(fetchCreatePage(props.urlPage, pageSettings.langCode));
    }, [pageSettings.langCode])

    return (
        <div className="page-container contacts-page">
            {
                request.loading
                    ?
                    <Loader/>
                    :
                    <>
                        <h1 className="page-title blocks-padding">{request?.pageData?.title}</h1>
                        {
                            request?.pageData?.content.length > 0 &&
                            request?.pageData?.content.map((item, index) => {
                                return (
                                    <div key={`test${index}`}>
                                        {
                                            item.typeBlock === 'twoColumnBlock'
                                                ?
                                                <TextBlock data={item} titleClass="_small" key={`block_${index}`}/>
                                                :
                                                <div
                                                    className={`address-block blocks-padding ${item.typeBlock === "mapBlock" ? '_maps' : ''}`}
                                                    key={`block_${index}`}>
                                                    <div className="contact-block-title">
                                                        {item.blockTitle}
                                                    </div>
                                                    {
                                                        item.typeBlock === "addressBlock" &&
                                                        <div className="block-content blocks-padding">
                                                            <div className="block-address">
                                                                {item.blockAddress}
                                                            </div>
                                                            <div className="block-work">
                                                                {item.blockWorkTime}
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        item.typeBlock === "phonesBlock" &&
                                                        <div className="block-content blocks-padding _contact">
                                                            {item.blockPhones.length > 0 &&
                                                            <div className="content-item">
                                                                <div className="content-title">
                                                                    {pageSettings?.translate?.contact_phone_title}
                                                                </div>
                                                                <div className="content-links">

                                                                    {
                                                                        item.blockPhones.map((phone, phoneIndex) => {
                                                                            return (
                                                                                <a href={'tel:+38' + phone.replace(/\s|\(|\)|\-/gi, '')}
                                                                                   key={"phone_" + phoneIndex}>{phone}</a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>

                                                            </div>
                                                            }
                                                            {
                                                                item.blockEmails.length > 0 &&
                                                                <div className="content-item">
                                                                    <div className="content-title">
                                                                        {pageSettings?.translate?.email_title}

                                                                    </div>
                                                                    <div className="content-links">
                                                                        {item.blockEmails.map((email, emailIndex) => {
                                                                            return (
                                                                                <a href={'mailto:' + email}
                                                                                   key={"emails" + emailIndex}>{email}</a>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        item.typeBlock === "mapBlock" &&
                                                        <div className="block-content">
                                                            <GoogleMap/>
                                                            {
                                                                item?.blockText &&
                                                                <div className="text-formatted blocks-padding"
                                                                     dangerouslySetInnerHTML={{__html: item?.blockText}} />
                                                            }
                                                            {
                                                                item.linkText &&
                                                                <div className="blocks-padding">
                                                                    <a href={item.linkToPage} className="section-link"
                                                                       target="_blank">
                                                                        {item.linkText}
                                                                        <i className="icon icon-arrrow-right"></i>
                                                                    </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                        }
                                    </div>
                                )
                            })
                        }

                    </>
            }
        </div>
    )
}

export default Contact
import React from "react";
import "./LinkBlock.scss"
import {NavLink} from "react-router-dom";

export default function (props) {
    return(
        <>
            <div className="links-block">
                <div className="links-item">
                    {
                        props?.data?.firstBlockImage &&
                        <div className="img-wrapper">
                            <img src={props.data.firstBlockImage} alt=""/>
                        </div>
                    }
                    {
                        props?.data?.firstLinkToPage &&
                        <NavLink to={`/${props.data.firstLinkToPage}`}
                                 className="section-link">
                            {props.data.firstLinkText}
                            <i className="icon icon-arrrow-right"></i>
                        </NavLink>
                    }
                </div>
                <div className="links-item">
                    {
                        props?.data?.secondBlockImage &&
                        <div className="img-wrapper">
                            <img src={props.data.secondBlockImage} alt=""/>
                        </div>
                    }
                    {
                        props?.data?.secondLinkToPage &&
                        <NavLink to={`/${props.data.secondLinkToPage}`}
                                 className="section-link">
                            {props.data.secondLinkText}
                            <i className="icon icon-arrrow-right"></i>
                        </NavLink>
                    }
                </div>
            </div>
        </>
    )
}
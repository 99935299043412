import {combineReducers} from 'redux'
import pageStateReducer from "./pageState";
import createMenuReducer from "./createMenu";
import pageSettingsReducer from "./pageSettings"
import createRouteReducers from "./createRoute";
import createPageReducer from "./createPage";
import createCategoryReducer from "./createCaterory";
import createSocialReducer from "./createSocials"
import languageReducer from "./language";

export default combineReducers({
    pageState: pageStateReducer,
    createMenu: createMenuReducer,
    pageSettings: pageSettingsReducer,
    createRoute : createRouteReducers,
    createPage: createPageReducer,
    createCategory: createCategoryReducer,
    createSocial: createSocialReducer,
    language: languageReducer
})
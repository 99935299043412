import {
    PAGE_SET_START,
    PAGE_SET_SUCCESS,
    PAGE_SET_ERROR,
    PAGE_SET_LANG,
    TRANSLATE_TEXT_SUCCESS
} from "../actions/actionTypes";

const pageSetting = {
    address: '',
    workTime: '',
    mapLng: '',
    mapLat: '',
    mapKey: '',
    langId: '',
    langCode: '',
    phone: '',
    loading: false,
    error: null,
    translate: null,
    siteName: '',
    success: false
}

export default function pageSettingsReducer(state = pageSetting, action) {
    switch (action.type) {
        case PAGE_SET_START:
            return {
                ...state, loading: true
            }
        case PAGE_SET_SUCCESS :
            return {
                ...state,
                address: action?.data?.config_footer_address,
                workTime: action?.data?.config_work_time,
                mapLng: action?.data?.config_map_longitude,
                mapLat: action?.data?.config_map_latitude,
                mapKey: action?.data?.config_api,
                langId: action?.data?.config_language,
                phone: action?.data?.config_footer_phone,
                siteName: action?.data?.config_meta_title,
                loading: false,
                success: true
            }
        case  PAGE_SET_ERROR :
            return {
                ...state, loading: false, error: action.error
            }

        case PAGE_SET_LANG :
            return {
                ...state,
                langCode: action.langCode,
                langId: action.langId
            }

        case TRANSLATE_TEXT_SUCCESS:
            return {
                ...state,
                translate: action.data
            }

        default :
            return state
    }
}
import React, {useEffect} from "react"
import "./MarketPage.scss"
import TextBlock from "../../component/TextBlock/TextBlock";
import MainGallery from "../../component/MainGallery/MainGallery";
import {NavLink, useHistory} from "react-router-dom";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {
    fetchCreateCategories,
    backActiveCategory,
    fetchMarket,
    schemaMarketId,
    schemaSingleMarketId,
    fetchMarketSuccess
} from "../../store/actions/createCategory";
import Loader from "../../component/Loader/Loader";


function MarketPage(props) {
    const data = useSelector(state => state.createCategory.market, shallowEqual)
    const dataCategory = useSelector(state => state.createCategory)
    const pageSettings = useSelector(state => state.pageSettings)
    const dispatch = useDispatch();
    const history = useHistory()
    const content = {
        blockTitle: data.name,
        blockText: data.description
    }
    useEffect(() => {
        if (dataCategory.rootCategories.length === 0) {
            dispatch(fetchCreateCategories(pageSettings.langCode))
        }
        dispatch(fetchMarket(props.urlPage, pageSettings.langCode))
        return ()=>{
            dispatch(fetchMarketSuccess([]))
            // dispatch(fetchCreateCategoriesSecondSuccess([]))
        }
    }, [])

    useEffect(() => {
        dispatch(fetchMarket(props.urlPage, pageSettings.langCode));
    }, [pageSettings.langCode])
    const handleMainCategory = (rootId, parentId) => {
        const currentRootCategory = dataCategory.rootCategories.filter(item => {
            return item.categoryID === rootId
        })

        dispatch(backActiveCategory(parentId === 0 ? null : parentId))
        history.push('/' + currentRootCategory[0].urlPage)
    }
    return (
        <div className="page-container market-page">
            <h1 className="page-title blocks-padding">
                <i className="icon icon-left-arrow" onClick={() => {
                    handleMainCategory(data.rootID, data.childID)
                }}></i>
                {data.h1}

            </h1>
            {data.images &&
            <MainGallery gallery={data.images}/>
            }
            {content &&
            <TextBlock data={content} titleClass="_small"/>
            }
            <div className="market-info">
                {
                    data.openingHours !== '' &&
                    <div className="info-item info-work-time">
                        <div className="info-title">{pageSettings?.translate?.aside_time}</div>
                        <div className="info-text" dangerouslySetInnerHTML={{__html: data.openingHours}}/>
                    </div>
                }
                {
                    data.goods &&
                    <div className="info-item info-description">
                        <div dangerouslySetInnerHTML={{__html: data.goods}}/>
                    </div>
                }
                {
                    data.linkToSite &&

                    <div className="info-item info-site">
                        <div className="info-title">
                            {pageSettings?.translate?.site_link_title}
                        </div>
                        <a href={data.linkToSite} className="section-link" target="_blank">
                            {data.linkToSite}
                            <i className="icon icon-arrrow-right"></i>
                        </a>
                    </div>
                }
                {
                    data?.codeIDs?.length > 0 &&
                    <div className="info-item info-site">
                        <div className="info-title">
                            {pageSettings?.translate?.how_find_title}
                        </div>
                        <NavLink to="/schema"
                                 onClick={() => {
                                     dispatch(schemaMarketId([data.rootID, data.childID]))
                                     dispatch(schemaSingleMarketId(data.productID))
                                 }}
                                 className="section-link"
                        >
                            {pageSettings?.translate?.show_on_map_title}
                            <i className="icon icon-arrrow-right"></i>
                        </NavLink>
                    </div>
                }

            </div>
        </div>
    )
}

export default MarketPage
import React, {useCallback} from "react";
import "./Sidebar.scss"
import header_logo from "../../images/logo.png"
import {ScrollRotate} from 'react-scroll-rotate';
import {NavLink} from "react-router-dom"
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {menuClose} from "../../store/actions/pageState";


import MainMenu from "../Navigation/MainMenu/MainMenu";
import SiteAddress from "../SiteContacts/SiteAddress/SiteAddress";
import SiteWork from "../SiteContacts/SiteWork/SiteWork";
import SiteSocial from "../SiteContacts/SiteSocial/SiteSocial";
import Language from "../Language/Language";

function Sidebar() {
    const state = useSelector(state => state.pageState, shallowEqual)
    const pageSettings = useSelector(state =>state.pageSettings,shallowEqual)
    const dispatch = useDispatch();
    const triggerMenu = useCallback(() => dispatch(menuClose()), [dispatch])
    return (
        <>
            <aside className={`sidebar ${state.menuState ? 'active' : ''}`}>
                <NavLink to="/" className="aside-logo">
                    <ScrollRotate loops={1} method={'perc'}>
                        <img src={header_logo} alt=""/>
                    </ScrollRotate>
                </NavLink>
                <div className="mobile-text">
                    {pageSettings?.translate?.menu_title}

                </div>
                <MainMenu/>

                <SiteAddress/>
                <SiteWork/>
                <SiteSocial/>


                <Language/>
            </aside>
            <div className={`sidebar-overlay ${state.menuState ? 'active' : ''}`}
                 onClick={triggerMenu}/>
        </>
    )
}

export default Sidebar
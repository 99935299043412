import React, {useRef, useEffect} from 'react'
import './ProductsTab.scss'
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {
    fetchCreateCategories,
    fetchCreateCategoriesSecond,
    categoryActiveLink,
    categoryActiveName
} from "../../store/actions/createCategory";
import Slider from "react-slick";
import "../../pluginStyle/slick.scss";

import MarketItem from "../../component/MarketItem/MarketItem"
import Loader from "../Loader/Loader";


function ProductsTab() {
    const pageSettings = useSelector(state => state.pageSettings)
    const rootCategory = useSelector(state => state.createCategory, shallowEqual)
    const dispatch = useDispatch();
    const sliderEl = useRef();
    const linkText = useSelector(state => state.createCategory.activeCategoryName, shallowEqual)
    const categorylink = useSelector(state => state.createCategory.activeCategoryLink, shallowEqual)
    const settings = {
        dots: false,
        infinite: rootCategory.rootCategoriesSecond.length > 2 ? true : false,
        speed: 750,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        className: 'product-gallery',
        touchMove: false,
        autoplay: true,
        autoplaySpeed: 2800,
        easing: 'ease-in-out',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    touchMove: true,
                    infinite: rootCategory.rootCategoriesSecond.length > 1 ? true : false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    touchMove: true,
                    infinite: true,
                }
            }
        ]
    };

    const tabSetting = {
        dots: false,
        arrows: false,
        infinite: true,
        variableWidth: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 750,
        slidesToScroll: 1,
    }


    useEffect(() => {
        dispatch(fetchCreateCategories(pageSettings.langCode))
    }, [])
    useEffect(() => {
        dispatch(fetchCreateCategories(pageSettings.langCode))
    }, [pageSettings.langCode])

    function sliderPrev() {
        sliderEl.current.slickPrev()
    }

    function sliderNext() {
        sliderEl.current.slickNext()
    }

    function tabHandlerClick(catId, text, link) {
        dispatch(categoryActiveName(text))
        dispatch(categoryActiveLink(link))
        dispatch(fetchCreateCategoriesSecond(catId, pageSettings.langCode))
    }

    return (
        <>
            {rootCategory.rootCategories.length > 0 &&
            <div className="products-tab">
                <div className="section-title blocks-padding">
                    {pageSettings?.translate?.market_now_title}
                </div>

                <div className={"tab-list"}>
                    {
                        rootCategory.rootCategories.map((item, index) => {
                            return (
                                <button
                                    className={`tab ${rootCategory.firstCategoryID === item.categoryID ? 'active' : ''}`}
                                    key={"tab" + index}
                                    onClick={() => tabHandlerClick(item.categoryID, item.name, item.urlPage)}>
                                    {item.name}
                                </button>
                            )
                        })
                    }
                </div>
                <div className={"tab-list _mobile"}>
                    <Slider {...tabSetting}>
                        {
                            rootCategory.rootCategories.map((item, index) => {
                                return (
                                    <button
                                        className={`tab ${rootCategory.firstCategoryID === item.categoryID ? 'active' : ''}`}
                                        key={"tab" + index}
                                        onClick={() => tabHandlerClick(item.categoryID, item.name, item.urlPage)}>
                                        {item.name}
                                    </button>
                                )
                            })
                        }
                    </Slider>
                </div>
                <div className="panel-list">
                    {
                        rootCategory.loadingSecond
                            ?
                            <Loader/>
                            :
                            <div className="panel">
                                <Slider {...settings} ref={sliderEl}>
                                    {
                                        rootCategory.rootCategoriesSecond.length > 0
                                            ?
                                            rootCategory.rootCategoriesSecond.map((item, index) => {
                                                return (
                                                    <MarketItem key={"innerCategory" + index} item={item}/>
                                                )
                                            })
                                            :
                                            rootCategory.markets.map((item, index) => {
                                                return (
                                                    <MarketItem key={"innerCategory" + index} item={item}/>
                                                )
                                            })
                                    }

                                    {
                                        rootCategory.rootCategoriesSecond.length === 2 &&
                                        <div className={'market-item _empty'}></div>
                                    }
                                    {
                                        rootCategory.rootCategoriesSecond.length === 1 &&
                                        <>
                                            <div className={'market-item _empty'}></div>
                                            <div className={'market-item _empty'}></div>
                                        </>
                                    }
                                    {
                                        rootCategory.markets.length === 2 &&
                                        <div className={'market-item _empty'}></div>
                                    }
                                    {
                                        rootCategory.markets.length === 1 &&
                                        <>
                                            <div className={'market-item _empty'}></div>
                                            <div className={'market-item _empty'}></div>
                                        </>
                                    }
                                </Slider>
                                <div className="tab-control">
                                    <div className="prev blocks-padding" onClick={() => sliderPrev()}>
                                        <i className="icon"></i>
                                        {pageSettings?.translate?.back_link}
                                    </div>
                                    <NavLink
                                        to={`/${categorylink}`}
                                        className="section-link">
                                        {pageSettings?.translate?.see_title}&nbsp;
                                        <span>{linkText}</span><i
                                        className="icon icon-arrrow-right"></i>
                                    </NavLink>
                                    <div className="next blocks-padding" onClick={() => sliderNext()}>
                                        {pageSettings?.translate?.next_link}
                                        <i className="icon"></i>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            }
        </>
    )
}

export default ProductsTab
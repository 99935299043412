import React from "react";
import "./Footer.scss"
import {useSelector, shallowEqual} from "react-redux";

import footer1 from "../../images/decorate/footer1.png"
import footer2 from "../../images/decorate/footer2.png"

const request = {
    title: 'Нагорный рынок'
}

function Footer(props) {
    const pageSettings = useSelector(state => state.pageSettings, shallowEqual)
    return (
        <footer className={`page-footer`}>
            <div className="top-block blocks-padding">
                <div className="footer-info">
                    {request.title !== '' &&
                    <div className="footer-title">
                        {pageSettings.siteName[pageSettings.langId]}
                    </div>
                    }
                    {pageSettings.address[pageSettings.langId] !== "" &&
                    <div className="footer-address">
                        <i className="icon icon-location"></i>
                        {pageSettings.address[pageSettings.langId]}
                    </div>
                    }
                    {pageSettings.phone !== "" &&
                    <a href={`tel:+38${pageSettings.phone.replace(/\s|\(|\)|-/gi, '')}`} className="footer-phone">
                        <i className="icon icon-phone"></i>
                        {pageSettings.phone}
                    </a>
                    }
                </div>
            </div>
            <div className="images-block">
                <img className="footer-image _first" src={footer1} alt=""/>
                <img className="footer-image _second" src={footer2} alt=""/>
            </div>
            <div className="copyright blocks-padding">
                <div className="copyright-text">
                    {pageSettings.siteName[pageSettings.langId]}. {pageSettings?.translate?.footer_copyright_text}
                </div>
                <div>
                    2020 {pageSettings?.translate?.year_title}
                </div>
            </div>
        </footer>
    )
}

export default Footer
import {
    PAGE_SET_START, PAGE_SET_SUCCESS, PAGE_SET_ERROR, PAGE_SET_LANG, TRANSLATE_TEXT_SUCCESS
} from "./actionTypes";
import axios from "../../axios/axios-store";

export function fetchSettings() {
    return async dispatch => {
        dispatch(fetchSettingsStart())

        try {
            const response = await axios.post('settings')
            dispatch(fetchSettingsSuccess(response?.data?.settings))
            dispatch(translateText(response?.data?.settings.config_language))
        } catch (e) {
            dispatch(fetchSettingsError(e))
        }
    }
}

export function fetchSettingsStart() {
    return {
        type: PAGE_SET_START
    }
}

export function fetchSettingsSuccess(data) {
    return {
        type: PAGE_SET_SUCCESS,
        data
    }
}

export function fetchSettingsError(e) {
    return {
        type: PAGE_SET_ERROR,
        error: e
    }
}

export function changeDefaultLanguage(id, code) {
    return {
        type: PAGE_SET_LANG,
        langId: id,
        langCode: code,

    }
}

export function translateText(langCode) {
    return async dispatch => {
        try {
            const response = await axios.post(
                'translates',
                {
                    language: langCode
                }
            )

            dispatch(translateTextSuccess(response.data.translates))

        } catch (e) {
            console.log('translateText',e)
        }
    }
}

export function translateTextSuccess(data) {
    return {
        type: TRANSLATE_TEXT_SUCCESS,
        data
    }
}
import {MAIN_MENU_START, MAIN_MENU_SUCCESS, MAIN_MENU_ERROR} from "../actions/actionTypes";

const menuState = {
    menuList: [],
    loading: false,
    error: null
}

export default function createMenuReducer(state = menuState, action) {
    switch (action.type) {
        case MAIN_MENU_START:
            return {
                ...state, loading: true
            }
        case MAIN_MENU_SUCCESS :
            return {
                ...state, loading: false, menuList: action.menuList
            }
        case  MAIN_MENU_ERROR :
            return {
                ...state, loading: false, error: action.error
            }

        default :
            return state
    }
}
import {LAYOT_START, LAYOT_ERROR, LAYOT_SUCCESS} from "../actions/actionTypes";

const crateRouter = {
    loading: false,
    routes : [],
    error: null
}

export default function createRouteReducers(state = crateRouter,action) {
    switch (action.type){
        case LAYOT_START :
            return {
                ...state, loading: true
            }
        case LAYOT_SUCCESS :
            return {
                ...state, routes: action.data
            }

        case LAYOT_ERROR :
            return {
                ...state,error: action.error
            }
        default :
            return state
    }
}
import React from "react";
import "./SiteAddress.scss"
import {shallowEqual, useSelector} from "react-redux";
import Loader from "../../Loader/Loader";


function SiteAddress() {
    const pageSettings = useSelector(state => state?.pageSettings, shallowEqual)
    return (
        <>
            {
                pageSettings.loading === true
                    ?
                    <Loader/>
                    :
                    <div className="site-address">
                        {pageSettings.address[pageSettings.langId] !== "" &&
                        <div className="title">
                            {pageSettings?.translate?.aside_address}
                        </div>
                        }
                        {pageSettings.address[pageSettings.langId] !== "" &&
                        <div className="text">
                            {pageSettings.address[pageSettings.langId]}
                        </div>
                        }

                    </div>
            }
        </>

    )
}

export default SiteAddress
import {MAIN_MENU_START, MAIN_MENU_SUCCESS, MAIN_MENU_ERROR} from "./actionTypes";
import axios from "../../axios/axios-store";

export function fetchMenu(langCode) {
    return async dispatch => {
        dispatch(fetchMenuStart())

        try {
            const response = await axios.post(
                'menus/type/site/template/main_menu',
                {
                    language: langCode
                }
                )
            dispatch(fetchMenuSuccess(response?.data?.menuItems[0]))
        } catch (e) {
            dispatch(fetchMenuError(e))
        }
    }
}

export function fetchMenuStart() {
    return {
        type: MAIN_MENU_START
    }
}

export function fetchMenuSuccess(menuList) {
    return {
        type: MAIN_MENU_SUCCESS,
        menuList
    }
}

export function fetchMenuError(e) {
    return {
        type: MAIN_MENU_ERROR,
        error: e
    }
}
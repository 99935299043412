import React from "react";
import Slider from "react-slick";
import "./MainGallery.scss"
import "../../pluginStyle/slick.scss"


function SampleNextArrow(props) {
    const {onClick} = props;
    return (
        <div
            className="gallery-arrow _next"
            onClick={onClick}
        >
            <i className="icon icon-right-arrow"></i>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {onClick} = props;
    return (
        <div
            className="gallery-arrow _prev"
            onClick={onClick}
        >
            <i className="icon icon-left-arrow"></i>
        </div>
    );
}

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'main-gallery',
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>
};

function MainGallery(props) {
    const gallery = props.gallery
    return (
        <Slider {...settings}>
            {
                gallery.map((galItem, index) => {
                    return (
                        <img src={galItem.image} key={"gall_"+index} alt="" />
                    )
                })
            }
        </Slider>
    )
}

export default MainGallery
import React, {useCallback} from "react";
import {NavLink} from 'react-router-dom'
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import {menuOpen} from "../../store/actions/pageState";

import "./Header.scss"

import Language from "../Language/Language"
import header_logo from "../../images/logo.png";
import {ScrollRotate} from "react-scroll-rotate";

function Header() {
    const state = useSelector(state => state.pageState, shallowEqual)
    const pageSettings = useSelector(state => state.pageSettings, shallowEqual)
    const dispatch = useDispatch();

    const triggerMenu = useCallback(() => dispatch(menuOpen()), [dispatch])
    return (
        <header className="page-header">
            <div className="mobile-header blocks-padding">
                <div className="mobile-logo">
                    <NavLink to="/">
                        <div className="logo-img">
                            <ScrollRotate>
                                <img src={header_logo} alt=""/>
                            </ScrollRotate>
                        </div>
                        <div className="logo-text">
                            <div>
                                {pageSettings.siteName[pageSettings.langId]}
                            </div>
                        </div>
                    </NavLink>
                </div>
                {
                    pageSettings.phone !== "" &&
                    <a href={`tel:+38${pageSettings.phone.replace(/\s|\(|\)|-/gi, '')}`} className="header-mobile-phone"><i className="icon-phone"/></a>
                }
                <div className="hamburger-box-outer">
                    <div className={`hamburger-box ${state.menuState ? 'active' : ''}`}
                         onClick={triggerMenu}>
                        <div className="hamburger-inner"></div>
                    </div>
                </div>
            </div>
            <div className="top-header">
                <div className="big-name">
                    {pageSettings.siteName[pageSettings.langId]}
                </div>
                <Language/>
            </div>
        </header>
    )
}

export default Header